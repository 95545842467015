<template>
  <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">
            <!-- ***** Logo Start ***** -->
            <router-link to="/" class="logo">
              <img src="../assets/imgs/logo.jpg" />
            </router-link>
            <!-- ***** Logo End ***** -->
            <!-- ***** Menu Start ***** -->
            <ul :class="closedNav ? 'nav' : 'nav opened-nav'">
              <li @click="closedNav = true" class="scroll-to-section">
                <router-link to="/">Home</router-link>
              </li>
              <li @click="submenuActive = !submenuActive" class="submenu">
                <router-link to="/products">Products</router-link>
                <ul :class="submenuActive ? '' : 'active'">
                  <li @click="closedNav = true">
                    <router-link to="/products/led-screens"
                      >Led Screens</router-link
                    >
                     <router-link to="/products/led-control-system"
                      >Led Control System</router-link
                    >
                    <router-link to="/products/broadcast-converters"
                      >Broadcast Converters</router-link
                    >
                    <router-link to="/products/distribution-amplifiers"
                      >Distribution Amplifiers</router-link
                    >
                    <router-link to="/products/fiber-optic-extenders"
                      >Fiber Optic Extenders</router-link
                    >
                    <router-link to="/products/matrix-switchers-and-routers"
                      >Matrix Switchers and Routers</router-link
                    >
                    <router-link to="/products/multiviewers"
                      >Multiviewers</router-link
                    >
                  
                    <router-link to="/products/video-switchers-and-mixers"
                      >Video Switchers and Mixers</router-link
                    >
                    <router-link to="/products/video-encoders"
                      >Video Encoders</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="scroll-to-section">
                <router-link to="/about">About Us</router-link>
              </li>
              <li class="scroll-to-section">
                <a href="#contact">Contact</a>
              </li>
            </ul>
            <div @click="closedNav = !closedNav" class="menu-trigger">
              <span>Menu</span>
            </div>
            <!-- ***** Menu End ***** -->
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      categories: [
        { name: "Broadcast Converters" },
        { name: "Distribution Amplifiers" },
        { name: "Fiber Optic Extenders" },
        { name: "Led Screens" },
        { name: "Led Control System" },
        { name: "Matrix Switchers and Routers" },
        { name: "Multiviewers" },
     
        { name: "Video Switchers and Mixers" },
        { name: "Video Encoders" },
      ],
      closedNav: true,
      submenuActive: true,
    };
  },
};
</script>