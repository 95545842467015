<template>
  <div>
    <div style="padding-top:132px" class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-heading">
            <h2>
              {{
                this.$route.params.product
                  .replace(/-/g, " ")
                  .replace(/\w\S*/g, function (txt) {
                    return (
                      txt.charAt(0).toUpperCase() +
                      txt.substr(1).toLowerCase()
                    );
                  })
              }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <section class="section" id="product">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="left-images">
              <div v-if="loadingImg" class="loader-container">
                <span class="loader"></span>
              </div>
              <img @load="IsImgLoaded()" v-for="img in product.imgs" :key="img" :src="img" />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="right-content">
              <h4>{{ product.name }}</h4>
              <span class="price">{{ product.brand }}</span>
              <span>{{ product.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingImg:true,
      product: {
        name: "",
        category: "",
        slug: "",
        brand: "",
        img: "",
        imgs: [],
        description: "",
      },
    };
  },
  metaInfo() {
    return {
      title: `${this.product.name} | ${this.product.category
        .replace(/-/g, " ")
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })}`,
    };
  },
  methods: {
    IsImgLoaded() {
      this.loadingImg = false;
    },
  },
  mounted() {
    this.product = this.$parent.products.find(
      (item) => item.slug === this.$route.params.product
    );
  },
};
</script>

