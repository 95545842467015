<template>
  <footer id="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="first-item">
            <div class="logo">
              <img src="../assets/imgs/logo.jpg" />
            </div>
            <ul>
              <li>
                <router-link to="#"
                  >6th floor of building D, Hanhaida industrial Park, Guangming,
                  Shenzhen, China</router-link
                >
              </li>
              <li><router-link to="#">linda-lee2005@163.com</router-link></li>
              <li><router-link to="#">0086 755 2981 2673</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <h4>Useful Links</h4>
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
          </ul>
        </div>

        <div class="col-lg-3">
          <h4>Products</h4>
          <ul>
            <li>
              <router-link to="/products/broadcast-converters"
                >Broadcast Converters</router-link
              >
            </li>
            <li>
              <router-link to="/products/distribution-amplifiers"
                >Distribution Amplifiers</router-link
              >
            </li>
            <li>
              <router-link to="/products/fiber-optic-extenders"
                >Fiber Optic Extenders</router-link
              >
            </li>
            <li>
              <router-link to="/products/led-screens">Led Screens</router-link>
            </li>
            <li>
              <router-link to="/products/led-control-system"
                >Led Control System</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <h4>More</h4>
          <ul>
            <li>
              <router-link to="/products/matrix-switchers-and-routers"
                >Matrix Switchers and Routers</router-link
              >
            </li>
            <li>
              <router-link to="/products/multiviewers"
                >Multiviewers</router-link
              >
            </li>
       
            <li>
              <router-link to="/products/video-switchers-and-mixers"
                >Video Switchers and Mixers</router-link
              >
            </li>
            <li>
              <router-link to="/products/video-encoders"
                >Video Encoders</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-12">
          <div class="under-footer">
            <p>Shenzhen Kerosa Technology Co., Limited</p>
         
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      categories: [
        { name: "Broadcast Converters" },
        { name: "Distribution Amplifiers" },
        { name: "Fiber Optic Extenders" },
        { name: "Led Screens" },
        { name: "Led Control System" },
        { name: "Matrix Switchers and Routers" },
        { name: "Multiviewers" },
  
        { name: "Video Switchers and Mixers" },
        { name: "Video Encoders" },
      ],
    };
  },
};
</script>