<template>
  <div id="app">
    <Nav />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    Nav,
    Footer,
  },

  data() {
    return {
      products: [
      {
          name: "MCTRL4K Controller",
          category: "led-control-system",
          slug: "mctrl4k-controller",
          brand: "Novastar",
          img: "https://oss.novastar.tech/uploads/2019/03/4K-%E6%AD%A3%E9%9D%A2-RGB-%E5%B8%A6%E7%95%8C%E9%9D%A2-e1553569482282.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_392",
          imgs: [
            "https://oss.novastar.tech/uploads/2019/03/4K-%E6%AD%A3%E9%9D%A2-RGB-%E5%B8%A6%E7%95%8C%E9%9D%A2-e1553569482282.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_392",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/ventas%2Fcontrolador-novastar-mctrl4k%2FMCTRL4K-2.jpg?alt=media&token=4c465487-1185-4fde-8821-1e45ae32ef4d",
            "https://oss.novastar.tech/uploads/2020/10/MCTRL4K-1.png",
            "https://oss.novastar.tech/uploads/2020/10/MCTRL4K-12.png",

          ],
          description:
            "MCTRL4K utilizes HDR10-Optima&HLG to realize high dynamic range display. When paired with A8s/A10s Plus, the MCTRL4K can dramatically improve image quality through the optimization of every pixel. Resolution: 4K×2K@60Hz Maximum width up to 8K.Multi Outputs:Neutrik EtherCON×16 OPT Ports×4.Multiple Video Inputs:1×DP1.2, 1×HDMI2.0, 2×Dual DVI",
        },
        {
          name: "MCTRL660 Controller",
          category: "led-control-system",
          slug: "mctrl660-controller",
          brand: "Novastar",
          img: "https://oss.novastar.tech/uploads/2019/10/MCTRL660-3.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_576",
          imgs: [
            "https://oss.novastar.tech/uploads/2019/10/MCTRL660-3.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_576",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/nuevos%2Fnova%20660.jpg?alt=media&token=24097f30-ba80-4db7-8f68-c41094a97c1c",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/nuevos%2Fnova%20660%20back%20l.jpg?alt=media&token=f38db226-f20e-4556-98f1-9c1eb9c18d6a",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/nuevos%2Fnova%20660%20back.jpg?alt=media&token=970ab9d8-edc4-41b5-83f1-4e95e97e2bfa"
          ],
          description:
            "MCTRL660 is a controller developed by NovaStar with a single-unit loading capacity of 1920×1200@60Hz. MCTRL600 utilizes UART to cascade and control multiple units. Suitable for a variety of applications, especially rental and fixed installations such as stage broadcasts, monitoring centers, sports arenas, and more.12bit/10bit HDMI input, DVI input, HDMI.DVI loop output for cascading or monitoring, HDCP Blue-ray directly input, 8bit video source loading capacity of 1920×1200@60Hz., 12bit video source loading capacity of 1440×900@60Hz.Support for custom resolutions: 3840 pixels of H resolution, 3840 pixels of V resolution. Supports cascading for control of multiple units. Support for multiple signal formats: RGB, YCrCb4:2:2, YCrCb4:4:4. Certifications, CE, RoHS, FCC, UL, EAC",
        },
        {
          name: "MCTRL600 Controller",
          category: "led-control-system",
          slug: "mctrl600-controller",
          brand: "Novastar",
          img: "https://oss.novastar.tech/uploads/2020/05/MCTRL600-800%C3%97800_1-1.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_1024",
          imgs: [
            "https://oss.novastar.tech/uploads/2020/05/MCTRL600-800%C3%97800_1-1.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_1024",

          ],
          description:
            "MCTRL600 is a controller developed by NovaStar with a single-unit loading capacity of 1920×1200@60Hz. MCTRL600 utilizes UART to cascade and control multiple units. Suitable for a variety of applications, especially rental and fixed installations such as stage broadcasts, monitoring centers, sports arenas, and more.12bit/10bit HDMI input, DVI input, HDMI input, external signal input, HDCP Blue-Ray input, 8bit video source loading capacity of 1920×1200@60Hz., 12bit video source loading capacity of 1440×900@60Hz.Support for custom resolutions: 3840 pixels of H resolution, 3840 pixels of V resolution Supports cascading for control of multiple units Support for multiple signal formats: RGB, YCrCb4:2:2, YCrCb4:4:4 Certifications, CE, RoHS, FCC, EAC, IC",
        },

        {
          name: "MCTRL660 PRO Controller",
          category: "led-control-system",
          slug: "mctrl660-pro-controller",
          brand: "Novastar",
          img: "https://oss.novastar.tech/uploads/2021/01/MCTRL660-PRO-5.png",
          imgs: [
            "https://oss.novastar.tech/uploads/2021/01/MCTRL660-PRO-5.png",
            "https://oss.novastar.tech/uploads/2020/09/660pro-line.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_482",


          ],
          description:
            "High image quality, Ultra Low Latency,Mirroring & Rotation, Easily Switch, Stable & Secure.Supports 10bit/12bit depth, an increase of 4,096 times over the 16.7 million colors provided by 8bit, for a more detailed and realistic image.1920×1200@60Hz loading capacity, RGB independent Gamma adjustment, and point to point brightness and color calibration all work to provide a more realistic and detailed image.With video source output of less than 1ms, latency is effectively unnoticeable. This ensures high-quality display of content, with no need to worry about the negative influence of latency on a presentation.Support for mirroring and rotation of a single or dual ports, allowing a stunning effect with the push of a button.6× 1G network port outputs, and 2x 10G optical outputs. Support for sending/optical transmission mode switching. increasing transmission speed, while making long-distance transmission easy. Saves on operating cost and convenience.One-button restore from backup. Support for Web control and input monitoring, to eliminate problems as soon as possible.",
        },
        {
          name: "MCTRL300 Controller",
          category: "led-control-system",
          slug: "mctrl300-controller",
          brand: "Novastar",
          img: "https://oss.novastar.tech/uploads/2020/05/MCTRL300-1.jpg?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_885",
          imgs: [
            "https://oss.novastar.tech/uploads/2020/05/MCTRL300-1.jpg?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_885",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/nuevos%2FNovaStar-MCTRL300.png?alt=media&token=566bdac1-c894-4412-a112-4edc78e943f6"
          ],
          description:
            "MCTRL300 is NovaStar’s independent control card from the M3 Series, and supports audio and video input. The M3 undertakes encoding and data processing, sending the audio and video to the display. A single card supports maximum loading capacity of 2048×668@60Hz. It communicates with PC via USB port for convenience., DVI video input, Audio transmission, Dual-Ethernet output, with dual-Ethernet cable hot backup and multiple controller hot backup, USB port control, for cascading and control of multiple units, 1.3 million pixel loading capacity, with resolutions of 1280×1024@60Hz, 1024×1200@60Hz, 1600×848@60Hz, 1920×712@69Hz,, 2048×668@60Hz and Maximum height: 3840; Maximum height:3840. 1 light sensor interface, adjust the screen brightness automatically. Independent power supply. Certifications: CE, RoHS, EAC, FCC, IC",
        },
        {
          name: "MCTRL R5 Controller",
          category: "led-control-system",
          slug: "mctrl-r5-controller",
          brand: "Novastar",
          img: "https://oss.novastar.tech/uploads/2019/08/R5.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_280",
          imgs: [
            "https://oss.novastar.tech/uploads/2019/08/R5.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_280",
          ],
          description:
            "4K×1K Resolution, HDMI / DVI / 6G-SDI, Free Rotation.The MCTRL R5 is the first LED display controller of NovaStar that supports display rotation. A single MCTRL R5 features a loading capacity of up to 3840×1080@60Hz.It supports any custom resolutions within this capacity, meeting the on-site configuration requirements of ultra-long or ultra-wide LED displays. Working with the A8s or A10s Plus receiving card, the MCTRL R5 supports free screen configuration in SmartLCT and allows for display rotation at any angle to present a variety of images and bring an amazing visual experience to users.The MCTRL R5 can be mainly used in rental and fixed applications, such as concerts, live events, monitoring centers, Olympic Games and various sports centers.",
        },
        {
          name: "MCTRL700 Controller",
          category: "led-control-system",
          slug: "mctrl700-controller",
          brand: "Novastar",
          img: "https://oss.novastar.tech/uploads/2020/09/MCTRL700%E6%AD%A3%E9%9D%A2%E5%9B%BE-1.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_561",
          imgs: [
            "https://oss.novastar.tech/uploads/2020/09/MCTRL700%E6%AD%A3%E9%9D%A2%E5%9B%BE-1.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_561",
          ],
          description:
            "DVI / HDMI1.3 / AUDIO 1920×1200 Resolutions. MCTRL700 is a video controller developed by NovaStar. It supports 1x DVI input, 1× HDMI input, 1× audio input, and 6 Ethernet outputs. A single unit can load 1920×1200@60Hz.MCTRL700 utilizes USB IN to connect with a PC, and serial UART to perform cascading. MCTRL700 is mainly suitable for applications in the rental and fixed installation sectors, such as concerts, live broadcasting, monitoring centers, Olympic events, sports arenas, and more. Supports 3 types of inputs: 1×SL-DVI（IN-OUT） 1×HDMI 1.3（IN-OUT） 1×AUDIO 8bit video source loading capacity of 1920×1200@60Hz. 12bit video source loading capacity of 1440×900@60Hz Supports 6x 1G network port outputs. Supports 1× USB control port Supports 2× UART control ports for cascading up to a maximum of 20 units. Point to point brightness and color calibration provided by NovaLCT and NOVACLB software. This software performs brightness and color calibration on every LED lamp, reducing color discrepancies, and ensuring uniform brightness and color over the entire display, providing a higher quality image.",
        },
        {
          name: "MV0430 – Mini 4CH SDI Multiviewer",
          category: "multiviewers",
          slug: "4-channel-sdi-multiviewer",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-1.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-1.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-6.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-5.jpg"
          ],
          description:
            "MV0430 is an exquisite and professional SDI multiviewer which features 4-channel 3G/HD/SD-SDI auto adaptive inputs and 2-channel 3G-SDI as well as 1-channel HDMI outputs. Total 16 preset layouts are provided for choices. User can also adjust the size and position of 11-character UMD and audio meter overlay freely. All the functions can be easily set by a built-in DIP switch, or PC via LAN. MV0430 is your ideal choice for broadcasting and AV post production applications.",
        },
        {
          name: "MMV1630 – 16CH SDI Mutltivewer & Switcher",
          category: "multiviewers",
          slug: "16ch-sdi-multiviewer",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-1.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-1.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-7.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-6.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-5.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-8.jpg",
          ],
          description:
            "MMV1630 is the perfect solution for a large broadcast project. One machine supports two modes, 16 channel SDI multiviewer mode and 16×16 SDI matrix switcher. The input signal Automatically detects SD, HD and 3G-SDI. The video format of multiview output can be selected from total 9 fomats from 720p to 1080p. Besides, 20 standard layouts with 16/12/11/10/9/8/6/4 preset in this device. You can even SOLO any input signal in full screen through the shortcut key. MMV16310 also supports UMD, audio meter, signal status and time code overlay. All functions can be quickly controlled by the PC software or front panel button & knob.",
        },
       
        {
          name: "SC1120 – SDI to HDMI Scaling Converter",
          category: "broadcast-converters",
          slug: "3g-sdi-to-hdmi-av-scaling-converter",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-6.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-6.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-5.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-1.jpg"
          ],
          description:
            "SC1120 is an exquisite and professional SDI converter to convert SDI signal to HDMI and AV signals at the same time. The format of the output can realize pass through or up/down scaling and frame rate conversion. De-interlacing function and seamless conversion can also be supported to avoid jitter. It also features AES/EBU or analog audio embedding and de-embedding. All the functions can be easily set by a built-in DIP switchers or PC via USB. SC1120 is your ideal choice for broadcasting and AV post production applications.",
        },
        {
          name: "FE1121-12G 12G-SDI Fiber Optic Extender",
          category: "fiber-optic-extenders",
          slug: "12g-sdi-fiber-optic-extender",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2017/09/FE1121-12G.png",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2017/09/FE1121-12G.png",
            "https://www.avmatrix.net/wp-content/uploads/2019/09/FE1121-12G-2.png"
          ],
          description:
            "12G/6G/3G-SDI input & loopout on transmitter Dual 12G-SDI outputs on receiver Supports up to 12G bandwidth SDI input automatic switching SD, HD, 3G, 6G, 12G-SDI 10km transmission distance SFP/ LC Fiber transmitter & receiver SDI equalization and re-clocking USB power supply",
        },
        {
          name: "E1121 3G-SDI Fiber Optic Extender",
          category: "fiber-optic-extenders",
          slug: "3g-sdi-fiber-optic-extender",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/FE1121-4.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/FE1121-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/FE1121-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/FE1121-1.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/FE1121-6.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/FE1121-5.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/FE1121-2.jpg"
          ],
          description:
            "The FE1121 3G-SDI Fiber Optic Extender is a transmitter/receiver kit supporting single-mode LC simplex fiber extensions up to 20km. It is a miniature-style extension solution that supports all SDI formats resolutions up to 1080p with audio and metadata, also supports level A and level B. It features a local SDI loop out and dual SDI outputs and supports 3G/HD/SD SDI signals. Multimode fiber can also be used but at shorter distances.",
        },
        {
          name: "SD1191 – 1×9 SDI Distribution Amplifier",
          category: "distribution-amplifiers",
          slug: "1x9-sdi-reclocking-distribution-amplifier",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1191-2.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1191-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2017/08/SD1191-4-300x300.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1191-5.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1191-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1191-6.jpg",
          ],
          description:
            "The SD1191 is a miniature 1×9 3G/HD/3G-SDI re-clocking distribution amplifier. The SD/HD/3G-SDI input signal is automatically detected. It features cable equalization and re-clocking techniques that enable the SDI cable distance up to SD: 400m, HD: 200m, 3G: 120m. It is also an ASI distributor amplifier that all input and outputs are compatible with ASI signal.",
        },
        {
          name: "SD1141 – 1×4 SDI Distribution Amplifier",
          category: "distribution-amplifiers",
          slug: "sdi-reclocking-distribution-amplifier",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1141-1.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1141-1.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1141-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1141-5.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1141-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1141-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SD1141-6.jpg",

          ],
          description:
            "The SD1141 is a miniature, low-cost 1×4 3G HD-SDI/HD-SDI/SDI re-clocking distribution amplifier. Featuring four separately buffered SDI outputs, the SD1141 provides automatic input detection, re-clocking and cable equalization.",
        },
        {
          name: "SD1121-12G – 12G-SDI SIGNAL REPEATER",
          category: "distribution-amplifiers",
          slug: "12g-sdi-repeater",
          brand: "Avmatrix",
          img: "https://www.avmatrix.com/wp-content/uploads/2022/03/SD1121-12G2-300x300.jpg",
          imgs: [
            "https://www.avmatrix.com/wp-content/uploads/2022/03/SD1121-12G2-300x300.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2022/03/SD1121-12G-300x300.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2022/03/SD1121-12G3-300x300.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2022/03/sd1121-12G6-300x300.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2022/03/SD1121-12G4-300x300.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2022/03/SD1121-12G5-300x300.jpg",
          ],
          description:
            "Input: 1×12G-SDI, Output: 2×12G-SDI. Automatically detects SD, HD, 3G, 6G and 12G-SDI. Support DVB-ASI signal. Automatic cable equalization and signal retiming. Pass all ancillary data and embedded audio. 5V micro USB power. Detachable bracket design for wall mounting",
        },
        {
          name: "SD2080 – 2×8 SDI/ HDMI SPLITTER & CONVERTER",
          category: "distribution-amplifiers",
          slug: "sd2080-2x8-sdi-hdmi-splitter-converter-distribution-amplifier",
          brand: "Avmatrix",
          img: "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080-7.jpg",
          imgs: [
            "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080-7.jpg",
          ],
          description:
            "Auto-detected input: 1×HDMI/1×3G-SDI Pass through output: 4×3G-SDI, 4×HDMI Distribute and convert between SDI & HDMI SDI with equalization and re-clock Input and output support up to 1080p60 Switching input signal easily via DIP switches USB and threaded locking DC power supply Detachable bracket design for wall mounting",
        },
        {
          name: "SD1151-12G – 12G-SDI Distribution Amplifier",
          category: "distribution-amplifiers",
          slug: "12g-sdi-da-sd1151-12g",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2020/03/SD1151-12G-3-300x300.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2020/03/SD1151-12G-3-300x300.jpg",
          ],
          description:
            "12G/6G/3G/HD/SD-SDI multi-rate signal processing 5 buffered and re-clocked outputs 1 channel SFP output for optional Support DVB-ASI signal Automatic cable equalization and signal retiming All ancillary data is passed to the outputs LED indicators for power and signal lock Threaded locking power connector Detachable bracket design for wall mounting",
        },
        {
          name: "MSS1611-S 16×16 Seamless SDI Matrix Switcher",
          category: "matrix-switchers-and-routers",
          slug: "16x16-3g-sdi-seamless-matrix-switcher",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2019/08/MSS1611S11-300x300.png",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2019/08/MSS1611S11-300x300.png",
          ],
          description:
            "MSS1611-S is the perfect solution for a large broadcast project. The compact SDI routing switcher features 16 inputs and 16 outputs, redundant power connections and an elegant machined metal front panel with 2.2 inch LCD for routing label display. Routing can be performed by direct button selection and the spin knob controller. The 3G-SDI seamless matrix switcher supports standard 3G/HD/SD SDI rates, automatic bypass of non-standard rates between 270Mbit/s and 3Gbit/s. Built-in frame synchronizer support simultaneous input of signal of different resolution frame rates, which can realize seamless switching without black screen. It can conveniently controlled by front panel button, PC software via LAN/RS-422.",
        },
        {
          name: "MMV1630 – 16CH SDI Mutltivewer & Switcher",
          category: "matrix-switchers-and-routers",
          slug: "16ch-sdi-multiviewer",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-4.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-4.jpg",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/ventas%2Fmatriz-multiviewer-sdi-hd-16x16%2FMMV1630%20(9).png?alt=media&token=158753cf-c37f-4601-bbfa-2a3830bcb156", 
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/ventas%2Fmatriz-multiviewer-sdi-hd-16x16%2FMMV1630%20(5).jpg?alt=media&token=4cc49b6a-c7ed-4f54-98c1-0a3e957449b8", 
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/ventas%2Fmatriz-multiviewer-sdi-hd-16x16%2FMMV1630%20(7).jpg?alt=media&token=20ae9a0a-1e3b-48f9-b802-bc846169548b", 
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/ventas%2Fmatriz-multiviewer-sdi-hd-16x16%2FMMV1630%20(8).jpg?alt=media&token=6ae4a099-ef2e-4e34-888b-5d7f0682bdbd", 
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/ventas%2Fmatriz-multiviewer-sdi-hd-16x16%2FMMV1630%20(8).jpg?alt=media&token=6ae4a099-ef2e-4e34-888b-5d7f0682bdbd", 
          ],
          description:
            "MMV1630 is the perfect solution for a large broadcast project. One machine supports two modes, 16 channel SDI multiviewer mode and 16×16 SDI matrix switcher. The input signal Automatically detects SD, HD and 3G-SDI. The video format of multiview output can be selected from total 9 fomats from 720p to 1080p. Besides, 20 standard layouts with 16/12/11/10/9/8/6/4 preset in this device. You can even SOLO any input signal in full screen through the shortcut key. MMV16310 also supports UMD, audio meter, signal status and time code overlay. All functions can be quickly controlled by the PC software or front panel button & knob.",
        },
        {
          name: "MSS0811 – 8×8 3G-SDI Matrix Switcher",
          category: "matrix-switchers-and-routers",
          slug: "8x8-3g-sdi-matrix-switcher",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/MSS0811-3.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MSS0811-3.jpg",
          ],
          description:
            "The compact SDI routing switcher MSS0811 has 8 inputs and 8 outputs, supports standard 3G/HD/SD SDI rates, automatic bypass of non-standard rates between 20 Mbit/s and 3 Gbit/s, and passes all SDI embedded audio/ancillary data. It’s conveniently control by front panel button, keyboard via USB and PC software via LAN/RS-232.",
        },

        {
          name: "2×8 SDI/ HDMI SPLITTER & Converter",
          category: "broadcast-converters",
          slug: "sd2080-2x8-sdi-hdmi-splitter-broadcast-converter",
          brand: "Avmatrix",
          img: "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080-7.jpg",
          imgs: [
            "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080-7.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080-8.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080-6.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080-5.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080-4.jpg",
            "https://www.avmatrix.com/wp-content/uploads/2021/12/SD2080-1.jpg",

          ],
          description:
            "Auto-detected input: 1×HDMI/1×3G-SDI. Pass through output: 4×3G-SDI, 4×HDMI. Distribute and convert between SDI & HDMI. SDI with equalization and re-clock. Input and output support up to 1080p60. Switching input signal easily via DIP switches. USB and threaded locking DC power supply. Detachable bracket design for wall mounting",
        },
        {
          name: "SC2030 - HDMI/SDI Scaling Cross Converter",
          category: "broadcast-converters",
          slug: "cross-converter-sc2030",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2020/03/SC2030-5-300x300.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2020/03/SC2030-5-300x300.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/03/SC2030-7-300x300.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/03/SC2030-1-300x300.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/03/SC2030-8-300x300.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/03/SC2030-5-300x300.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/03/SC2030-4-300x300.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/03/SC2030-6-300x300.jpg",
          ],
          description:
            "Input: HDMI×1 or SDI×1, analog audio×1 Output: 3G-SDI×2, HDMI×1 Up/down scaling and frame rate conversion Analog audio Embedding HDMI/SDI Input automatic detection and configuration Control easily via the DIP switches USB is used for PC software control and power supply Dual threaded locking power, for redundant power supply Detachable bracket design for wall mounting",
        },
        {
          name: "SC2031 – HDMI to SDI Scaling Converter",
          category: "broadcast-converters",
          slug: "hdmi-to-3g-sdi-scaling-converter",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/SC2031-2.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC2031-2.jpg",
          ],
          description:
            "SC2031 is an exquisite and professional HDMI converter to convert HDMI or AV signals to triple SDI signals up to 1080P60Hz. The format of the output can realize pass through or up/down scaling, frame rate conversion and de-interlacing function. It also features AES/EBU or analog audio embedding and de-embedding. All the functions can be easily set by a built-in DIP switchers or PC via USB. SC2031 is your ideal choice for broadcasting and AV post production applications.",
        },
        {
          name: "Mini SC1112 – SDI to HDMI Mini Converter",
          category: "broadcast-converters",
          slug: "3g-sdi-to-hdmi-converter",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1112-4.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1112-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1112-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1112-1.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1112-5.jpg",
          ],
          description:
            "The Mini SC1112 is a Pocket-size broadcast Converter, it converts SDI input signal to HDMI signal and with SDI loop out. it is very convenient for users with the full size SDI and HDMI connectors.",
        },
        {
          name: "Mini SC1221 – HDMI to SDI Mini Converter",
          category: "broadcast-converters",
          slug: "hdmi-to-3g-sdi-mini-converter",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1221-4.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1221-4.jpg",
          ],
          description:
            "The Mini SC1221 is a Pocket-size broadcast Converter, it converts HDMI input signal to dual SDI signal. it is very convenient for users with the full size SDI and HDMI connectors.",
        },
        {
          name: "HVS0401U-Micro 4-CH HDMI/ DP Video Switcher",
          category: "video-switchers-and-mixers",
          slug: "hvs0401u-micro-4-ch-hdmi-dp-video-switcher",
          brand: "Avmatrix",
          img: "https://www.avmatrix.com/wp-content/uploads/2022/10/HVS0401U-8-400x400.jpg",
          imgs: [
            "https://www.avmatrix.com/wp-content/uploads/2022/10/HVS0401U-8-400x400.jpg",
          ],
          description:
            " Inputs 1~3: HDMI, Input 4: HDMI/DP Outputs: HDMI PGM×2, HDMI multiview×1, USB type-C×1, LAN×1 USB Type-C for video capturing (UVC) PIP function, adjustable size and position Audio mixer: 3.5mm MIC/ RCA/ HDMI embedded audio AUTO/ CUT transitions and MIX/ FADE/ WIPE effects GPIO interface for live tally system FTB for emergency situation Function Parameter Application",
        },
        {
          name: "PVS0613 – Portable 6CH SDI/HDMI Video Switcher",
          category: "video-switchers-and-mixers",
          slug: "6ch-sdi-hdmi-video-switcher-pvs0613",
          brand: "Avmatrix",
          img: "https://www.avmatrix.com/wp-content/uploads/2020/02/PVS0613-1.jpg",
          imgs: [
            "https://www.avmatrix.com/wp-content/uploads/2020/02/PVS0613-1.jpg",
          ],
          description:
            "Inputs: 4×SDI and 2×HDMI inputs Outputs: 2×SDI &1×HDMI PGM, 1×SDI PVW, 1×SDI & HDMI multiview 13.3 inch FHD LCD monitor Portable design, metal case T-Bar/ AUTO/ CUT transitions and MIX/ FADE/ WIPE effects PiP mode, both size and position is adjustable Audio mixer: 3.5mm stereo/ RCA audio & SDI/ HDMI embedded audio GPIO interface for live tally system FTB for emergency situation",
        },
        {
          name: "VS0601U – Mini 6CH Streaming Switcher",
          category: "video-switchers-and-mixers",
          slug: "mini-6ch-steaming-video-switcher-vs0601u",
          brand: "Avmatrix",
          img: "https://www.avmatrix.com/wp-content/uploads/2020/11/VS0601U-4.jpg",
          imgs: [
            "https://www.avmatrix.com/wp-content/uploads/2020/11/VS0601U-4.jpg",
          ],
          description:
            "Uncompressed capture for live streaming, comply with UVC & UAC 6 channel inputs: 4×SDI & 2×HDMI 1×USB type-C output, 2×SDI & 1×HDMI PGM outputs, 1×SDI & 1×HDMI multiview outputs 1×SDI AUX output, can be selected as PGM or PVW Multi-format inputauto-detected and PGM outputs format selectable T-Bar/ AUTO/ CUT transitions Mix/ Fade/ Wipe transition effects PIP mode size and position adjustable Support audio MIXING and AFV mode GPIO for Tally, FTB for emergency",
        },
        {
          name: "PVS0615U – Portable 6CH Streaming Switcher",
          category: "video-switchers-and-mixers",
          slug: "pvs0615u-portable-6ch-sdi-hdmi-multi-format-streaming-switcher",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2020/11/PVS0615-1.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2020/11/PVS0615-1.jpg",
          ],
          description:
            "Uncompressed capture for live streaming, comply with UVC & UAC Portable All-In-One design with 15.6 inch FHD LCD display for multiview 6 channel inputs: 4×SDI and 2×HDMI/ DVI-I/ VGA/ USB 1×USB type-C out, 3×SDI PGM/ AUX out 2×HDMI PGM & 1×HDMI multiview out Multiformat input auto-detected and PGM/AUX output format selectable T-Bar/ AUTO/ CUT transitions and MIX/ FADE/ WIPE effects Luma Key/ Chroma Key/ Recordder/ Audio mixer: TRS audio & SDI, HDMI, USB embedded audio",
        },
        {
          name: "VS0605 – 6CH SDI/HDMI Video Switcher",
          category: "video-switchers-and-mixers",
          slug: "portable-standalone-6ch-sdihdmi-video-switcher-vs0605",
          brand: "Avmatrix",
          img: "https://www.avmatrix.com/wp-content/uploads/2020/02/VS0605-8.jpg",
          imgs: [
            "https://www.avmatrix.com/wp-content/uploads/2020/02/VS0605-8.jpg",
          ],
          description:
            "Multiformat input:SD, HD,FHD 6 channel inputs: 4×SDI and 2×DVI-I/ HDMI/ VGA/ USB player inputs 3×SDI/ HDMI PGM outputs, 1×SDI AUX output, 2×SDI/ HDMI multiview outputs T-Bar/ AUTO/ CUT transitions and MIX/ FADE/ WIPE effects T-Bar/AUTO/CUT transitions and MIX/ FADE/ WIPE effects Luma Key/ Chroma Key for virtual studio PIP/POP window size and position adjustable Record PGM video to SD card, up to 1080p60 Audio mixer: XLR/ RCA audio & SDI/ HDMI/ USB embedded audio Tally port for multiple camera system",
        },
        {
          name: "SE1117 – H.265/264 SDI Streaming Encoder",
          category: "video-encoders",
          slug: "se1117-h-265-264-sdi-streaming-encoder",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-9.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-9.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-1.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-10.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-6.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-7.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-5.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2021/04/SE1117-8.jpg",
          ],
          description:
            "1×SDI input, 1×SDI loop out, 1×Analog audio input Supports H.265/ H.264 stream encode protocol, up to 1080p60hz Dual-stream (main stream and sub stream) RTSP, RTP, RTMPS, RTMP, HTTP, UDP, SRT, unicast and multicast Video and audio streaming or single audio streaming Image and text overlay Mirror image & upside-down image Live stream with no need for connecting a computer",
        }, {
          name: "SE1217 – H.265/264 HDMI Streaming Encoder",
          category: "video-encoders",
          slug: "se1217-h-265-264-streaming-encoder",
          brand: "Avmatrix",
          img: "https://www.avmatrix.com/wp-content/uploads/2020/12/SE1217-0.jpg",
          imgs: [
            "https://www.avmatrix.com/wp-content/uploads/2020/12/SE1217-0.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/12/SE1217-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/12/SE1217-1.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/12/SE1217-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/12/SE1217-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/12/SE1217-6.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/12/SE1217-5.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/12/SE1217.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2020/12/SE1217-7.jpg",
          ],
          description:
            "1×HDMI input, 1×HDMI loop out, 1×Analog audio input Supports H.265/ H.264 stream encode protocol, up to 1080p60hz Dual-stream (main stream and sub stream) RTSP, RTP, RTMPS, RTMP, HTTP, UDP, SRT, unicast and multicast Video and audio streaming or single audio streaming Image and text overlay Mirror image & upside-down image Live stream with no need for connecting a computer",
        },
        {
          name: "Series Outdoor Led Screen",
          category: "led-screens",
          slug: "product-47-series-led-display",
          brand: "XLED",
          img: "https://www.yes-led.com/Uploads/202103/60519ccfc07f7.png",
          imgs: [
            "https://www.yes-led.com/Uploads/202103/60519ccfc07f7.png",
            "https://www.yes-led.com/Uploads/202103/6051a08ade7a7.png",
            "https://www.yes-led.com/Uploads/202103/6051a08b64a74.png",
          ],
          description:
            "Pixel Pitch: 4/5/6.6/8/10mm, Module Size: 320*480mm, Cabinet Size: 960*960mm,Operating Environment: outdoor. Clean and beautiful cabinet design Connection cables are hidden in structure, which effectively prevent the cables aging. 6 independent buckle design on module, twist and “Click” voice reminder, fast assembly; Ergonomic big handle design, more comfortable grip.High compatibility 6 different cabinet sizes available（960*960/ 960*480/1280*960/1280*480/640*960/1600*480mm).Shared cabinet frame,upgrade pixel pitch freely, save the purchase cost.One type for all 8-holes connection plate support front and rear installation; Compatible with 5050 and 4040 steel frame, one type for all, and makes construction more worry-free;",
        },
        {
          name: "Plus Outdoor",
          category: "led-screens",
          slug: "outdoor-plus-stage-led-wall",
          brand: "XLED",
          img: "https://www.liantronics.com/Uploads/image/20210629/60db00344db7d.png",
          imgs: [
            "https://www.liantronics.com/Uploads/image/20210629/60db00344db7d.png",
            "https://www.liantronics.com/Uploads/image/20210629/20210629111955_21446.png",
            "https://www.liantronics.com/Uploads/image/20210629/20210629112826_79858.png",
            "https://www.liantronics.com/Uploads/image/20210629/20210629112024_28379.png",
            "https://www.liantronics.com/Uploads/image/20210629/20210629112100_27018.png",
            "https://www.liantronics.com/Uploads/image/20210629/20210629112219_73572.png",
          ],
          description:
            "Special Angle Lock Multiple creative shape splicing enabled, various scenarios applicable.Mixed Splicing Support modules swapping and mixed splicing.Quick Installation and MaintenanceModules support front service.Up & Down Fast Lock Quick installation even by single person.Support Dislocation Splicing Cabinets can be spliced in irregular shapes like dislocations up and down or left and right to achieve greater creativity.Hanging Bar & Ground Bar in Universal Use. Save your cost and offer higher flexibility",
        },
        {
          name: "Outdoor Pro Led Display",
          category: "led-screens",
          slug: "outdoor-pro-led-display",
          brand: "XLED",
          img: "https://www.yes-led.com/Template/Tpl/Home/xys/Public/page2/images/p2.jpg",
          imgs: [
            "https://www.yes-led.com/Template/Tpl/Home/xys/Public/page2/images/p2.jpg",
            "https://www.yes-led.com/Template/Tpl/Home/xys/Public/page2/images/img4.jpg",
            "https://www.yes-led.com/Template/Tpl/Home/xys/Public/page2/images/img7.png",
            "",
          ],
          description:
            "Real Visual Reappearance Selecting full black LEDs and high gray high refresh IC, matching with superior control system, and adopting HDR technology, screen could reach 18 bits gray scale.",
        },
        {
          name: "Indoor Commercial LED Display",
          category: "led-screens",
          slug: "Indoor-Commercial-LED-Display",
          brand: "XLED",
          img: "https://www.liantronics.com/Uploads/image/20220811/62f47a6eda081.png",
          imgs: [
            "https://www.liantronics.com/Uploads/image/20220811/62f47a6eda081.png",
            "https://www.liantronics.com/Uploads/file/20220811/20220811033746_38455.png",
            "https://www.liantronics.com/Uploads/image/20220815/20220815031740_79134.jpg",
            "https://www.liantronics.com/Uploads/image/20220811/20220811031500_43514.jpg",
            "https://www.liantronics.com/Uploads/image/20220815/20220815031758_51527.jpg",
            "https://www.liantronics.com/Uploads/image/20220811/20220811031936_81803.jpg",
            "https://www.liantronics.com/Uploads/image/20220811/20220811055042_45040.jpg",
            "liantronics.com/Uploads/image/20220815/20220815032703_64172.jpg",
          ],
          description:
            "Perfection Expanded, Again. X generation of hot-selling BIM Series indoor commercial LED display Thinner, lighter, faster, better visuals, more practical & ergonomic Mixed-splice-enabled with BIM Plus & BIM MIN Series for more creative shapes Wide-range pixel pitch options to fit in your space.Considerate Details Upgraded one-piece molding die-cast BIM Plus-X cabinet features more ergonomic product details to elevate customer experience.Various Installation in High Efficiency Thinner & lighter high-precision cabinets feature interlocks to facilitate fast installation like direct wall mounting hanging & stacking.Mixed Splicing, High Practicality BIM Plus-X Series supports up-and-down mixed splicing of BIM Plus Series, sharing common modules; and left-and-right mixed splicing with BIM MIN Series, offering higher flexibility.Creative Splicing, Beyond Imagination BIM Plus-X allows 90° rotation without color cast and multiple creative seamless splicing including L shape, cube, concave & convex, etc.Friendly Front Maintenance Power supply, hub card and modules can all be maintained from the front to save your space. Advanced Technologies, Outstanding PerformancePatented smart inkjet craftsmanship allows vibrant and sharp presentation in higher contrast ratio; Unique nano coatingprovides higher protection against blind lamps, LED caterpillars, etc.",
        },
        {
          name: "ZX Indoor Led Display ",
          category: "led-screens",
          slug: "seriex-zx",
          brand: "XLED",
          img: "https://www.yes-led.com/Uploads/202003/5e7c54cc7e479.jpg",
          imgs: [
            "https://www.yes-led.com/Uploads/202003/5e7c54cc7e479.jpg",
            "https://www.yes-led.com/Uploads/202003/5e7c5215a7214.jpg",
            "https://www.yes-led.com/Uploads/202003/5e7c521617a91.jpg",
            "https://www.yes-led.com/Uploads/202003/5e7c52171ed99.jpg",
          ],
          description:
            "High Resolution Rental Product Pixel Pitch: 1.9mm Cabinet Size: 500*500*73mm Operating Environment: Indoor.Magic Stage Series--MG6S P1.9 Pro High Resolution Rental Product, for indoor application of high-end conference rental——meeting room，hotel, etc. High Stability Adopting 4 in 1 and size 30X30 LED, single LED adhesion can up to 60-70N. Strengthen LED adhesion, improving LED anti-collision ability; corner protection solve the collision problem for high resolution rental product. It is the most reliable and stable SMD solution for high resolution rental product.Safe and Reliable Supporting the double backup correction data and intelligent module management, which offers one more safeguard in debugging and ensures high stability, safety and reliability of the system.",
        },

        {
          name: "Series X Pro",
          category: "led-screens",
          slug: "series-x-pro",
          brand: "XLED",
          img: "https://www.liantronics.com/Uploads/image/20220602/62984e3bca73a.png",
          imgs: [
            "https://www.liantronics.com/Uploads/image/20220602/62984e3bca73a.png",
            "https://www.liantronics.com/Uploads/file/20211117/20211117013008_22039.png",
            "https://www.liantronics.com/Uploads/image/20211116/20211116093502_43495.jpg",
            "https://www.liantronics.com/Uploads/image/20211116/20211116093511_45138.jpg",
            "https://www.liantronics.com/Uploads/image/20211116/20211116093520_19575.jpg",
            "https://www.liantronics.com/Uploads/image/20211116/20211116093532_15809.jpg",
            "https://www.liantronics.com/Uploads/image/20211116/20211116093540_16056.jpg",
            "https://www.liantronics.com/Uploads/image/20211116/20211116093548_93286.jpg",
            "https://www.liantronics.com/Uploads/image/20211116/20211116093548_93286.jpg",
          ],
          description:
            "Fine-Pitch LED Display ,High Stability, High Clarity, High Protection. 16:9-golden-ratio small-sized panel. 8:9 larger RGB panel for faster assembly.Multiple optimizations for protection & stability upgrade. High-Definition Splicing Two-sized panels allow up-and-down mixed assembly to achieve mainstream FHD/4K/8K-resolution fine-pitch LED walls with pixel-to-pixel broadcasting.All-Around Protection Fully sealed cabinet with nano-coating craftsmanship improves resistance capabilities against dust, salt spray, moisture, etc.Aesthetics & Scientifility into One Built-in power supply attaching to aluminum back shell brings excellent heat dissipation; meanwhile, the external thermal slots with geometrical aesthetics further expand the cooling area.Hassle-Free Installation & Maintenance Support both front and rear installation while fully front-accessible serviceability makes more friendly maintenance and saves your space.Intelligent Monitoring & Calibration Optional LCD panel brings instant monitoring of cabinet operation; real-time monitoring, production information tracking and calibration retrieval of modules can all be realized.More Stable Performance Multiple internal structural designs assist in doubling transmission stability and also stabilizing the finished fine-pitch LED video wall overall working performance",
        },
        {
          name: "Series RXT",
          category: "led-screens",
          slug: "series-rxt",
          brand: "XLED",
          img: "https://www.liantronics.com/Uploads/image/20211020/616f828c4bbfa.png",
          imgs: [
            "https://www.liantronics.com/Uploads/image/20211020/616f828c4bbfa.png",
            "https://www.liantronics.com/Uploads/image/20200512/20200512181429_77219.png",
            "https://www.liantronics.com/Uploads/image/20200507/20200507105730_80035.png",
            "https://www.liantronics.com/Uploads/image/20211020/20211020024510_35911.png",
            "https://www.liantronics.com/Uploads/image/20211019/20211019095609_43829.png",
            "https://www.liantronics.com/Uploads/image/20200706/20200706144440_50220.png",
          ],
          description:
            "Excellent Display Effect, True-to-Life Presentation Super high contrast ratio by patented mask and ultra-uniform color bring your content to life.Multiple Protections, Super High Stability Full-waterproof modules are anti-UV to avoid deformation. IP65/IP54 high protection facilitates long-time stable running even under harsh weather.Dual Cooling Channels, Longer Working Hours Modules are separated from the power supply to double heat dissipation channels, which helps to extend the service lifespan to maximize the investment returns for you.Wider Viewing Angle, More Eyeballs Covered Super-wide 160° viewing angle captures more attention from viewers to maximize the advertisement value.Convenient Serviceability, Greater Flexibility By supporting front and rear installation and maintenance, FS LED panel can be spliced into L-shape, cylinder, concave and other shapes to match multi-occasion applications.",
        },
        {
          name: "Series T Pro Display",
          category: "led-screens",
          slug: "series-t-pro-display",
          brand: "XLED",
          img: "https://www.liantronics.com/Uploads/image/20220602/62984f43b5df7.png",
          imgs: [
            "https://www.liantronics.com/Uploads/image/20220602/62984f43b5df7.png",
            "https://www.liantronics.com/Uploads/file/20220215/20220215040601_16116.png",
            "https://www.liantronics.com/Uploads/image/20220602/20220602054902_10147.jpg",
            "https://www.liantronics.com/Uploads/image/20220602/20220602054910_79419.jpg",
            "https://www.liantronics.com/Uploads/image/20220602/20220602055015_85356.jpg",
            "https://www.liantronics.com/Uploads/image/20220602/20220602055026_60474.jpg",
            "https://www.liantronics.com/Uploads/image/20220602/20220602055034_77797.jpg",
            "https://www.liantronics.com/Uploads/image/20220602/20220602055041_60115.jpg",
          ],
          description:
            "Full Flip Chip on Board (COB) LED Display.LianTronics Next-Generation COB LED Display Self-develop COB packaging technology Full flip chip on board Self-built production line for scale production.A Vision to the Future Breaking through limitation of traditional packaging, VT Series has achieved ultra-finer pixel pitch below 1.0mm to produce crystal-clear images free of moire effect and over 10000:1 high contrast ratio for precise vibrant colors. Unprecedented Broad Views Advanced full flip-chip packaging ensures softer luminance and brings 170° wide views with immaculate image quality in high uniformity from any angle.Reinforced All-Around Protection.Low-LED-failure-rate modules feature outstanding anti-collision capacity and waterproof resined surface; One-piece molded.back shell conspires to provide high protection all around.Exceptional Stability Cabinet-and-module-level wireless connection grants high transmission stability and hassle-free installation; Redundancy of power supply, receiving card, power & network cables further minimize downtime and guarantee long-lasting stable broadcasting.Excellent Heat Dissipation, Excellent User Experience Attach-to-shell power supply and optimal circuit board layout bring excellent heat dissipation, lower surface temperature, and more stable operation, improving the user experience significantly.Quadruple Energy-Saving Technology Adopted common cathode technology and high-efficiency light-emitting chips reduce power consumption by 40%; Dynamic energy efficiency and smart standby mode further improve energy efficiency.",
        },

        {
          name: "Outdoor XRT",
          category: "led-screens",
          slug: "Outdoor XRT",
          brand: "XLED",
          img: "https://www.liantronics.com/Uploads/image/20220601/6296cf055e506.png",
          imgs: [
            "https://www.liantronics.com/Uploads/image/20220601/6296cf055e506.png",
            "https://www.liantronics.com/Uploads/image/20201118/20201118093939_62661.jpg",
            "https://www.liantronics.com/Uploads/image/20201015/20201015093929_14828.jpg",
            "https://www.liantronics.com/Uploads/image/20201015/20201015094002_62385.jpg",
            "https://www.liantronics.com/Uploads/image/20211209/20211209094313_63687.jpg",
          ],
          description:
            "Hot-Selling Outdoor LED Digital Billboard -- Hercules Series Up to 10000nits high brightness yet high energy efficiency Ultra-thin & light design for cost & labor saving Friendly front & rear accessible maintenance Direct wall-mounting supported Various sizes for high-flexibility customization IP65 high protection to survive well in outdoors.Unrivaled Brightness Up to 10000nits high brightness ensures sharp and natural-looking images even under direct sunlight or complex outdoor ambient light.Easy to MaintainAll components support both front and rear maintenance.Ideal Choice for OOH Billboard Designed to be thinner and lighter to save costs with high spatial adaptability, Hercules Series features high energy efficiency to improve commercial value for your outdoor advertising digital billboards.",
        },

      ],
    };
  },
};
</script>



