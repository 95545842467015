import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Products from '../views/Products.vue'
import About from '../views/About.vue'
import ProductsCategory from '../views/ProductsCategory.vue'
import Product from '../views/Product.vue'
import Meta from 'vue-meta'
Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/products',
    name: 'products',
    component: Products
  },  {
    path: '/about',
    name: 'about',
    component: About
  }, {
    path: '/products/:category',
    name: 'category',
    component: ProductsCategory
  }, {
    path: '/products/:category/:product',
    name: 'product',
    component: Product
  },

]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})



export default router
