<template>
  <div>
    <div class="main-banner" id="top">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="left-content">
              <div class="thumb">
                <div class="inner-content">
                  <h4>We Are Kerosa</h4>
                  <span>Since 2010 KRS Led is a leading display solution provider
                    specializing in LED application products from R&D</span>
                </div>
                <img src="../assets/imgs/1.jpg" />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="right-content">
              <div class="row">
                <div class="col-lg-6">
                  <div class="right-first-image">
                    <div class="thumb">
                      <div class="hover-content">
                        <div class="inner">
                          <h4>Led Screens</h4>
                          <div class="main-border-button">
                            <router-link to="/products/led-screens">Discover More</router-link>
                          </div>
                        </div>
                      </div>
                      <img src="../assets/imgs/g1.jpg" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="right-first-image">
                    <div class="thumb">
                      <div class="hover-content">
                        <div class="inner">
                          <h4>Led Control System</h4>
                          <div class="main-border-button">
                            <router-link to="/products/led-control-system">Discover More</router-link>
                          </div>
                        </div>
                      </div>
                      <img src="../assets/imgs/g3.jpg" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="right-first-image">
                    <div class="thumb">
                      <div class="hover-content">
                        <div class="inner">
                          <h4>Fiber Optic Extenders</h4>
                          <div class="main-border-button">
                            <router-link to="/products/fiber-optic-extenders">Discover More</router-link>
                          </div>
                        </div>
                      </div>
                      <img src="../assets/imgs/g2.jpg" />
                    </div>
                  
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="right-first-image">
                    <div class="thumb">
                      <div class="hover-content">
                        <div class="inner">
                          <h4>Broadcast Converters</h4>
                          <div class="main-border-button">
                            <router-link to="/products/broadcast-converters">Discover More</router-link>
                          </div>
                        </div>
                      </div>
                      <img src="../assets/imgs/g4.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <carousel autoplay :autoplayTimeout="3000" :perPage="1" loop>
      <slide class="banner-home slide-1"> </slide>
      <slide :title="`Imágen 2`" class="banner-home slide-2">
        <div class="banner-shadow"></div>
      </slide>
      <slide class="banner-home slide-3"> </slide>
      <slide class="banner-home slide-4"> </slide>
    </carousel>

    <div style="padding-top: 60px; padding-bottom: 60px" class="about-us">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="left-image">
              <img src="../assets/imgs/kerosa-factory.png" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="right-content">
              <h4>About Us</h4>
              <p>
                Since 2010 KRS Led is a leading display solution provider
                specializing in LED application products from R&D, we produce
                led screens for fixed installation and the rental market, we
                distribute professional video equipment and controls for led
                screens. Our products are best described as robust, reliable and
                sophisticated, we work hard to achieve synonyms of agility,
                flexibility, fashion, with a balanced approach. The brand is
                "based on quality" and strives to be the best in the LED display
                industry. "stage art around the world, stage shining in the
                world", aims to provide customers with the best service and
                jointly promote the development of the scenic display field.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section home-title" id="men">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="section-heading">
              <h2>Our Products</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="products">
      <div class="container">
        <div class="row">
          <ul>
            <li></li>
          </ul>
          <router-link :to="`products/${product.category}/${product.slug}`" v-for="product in products"
            :key="product.slug" class="col-lg-4 product">
            <div class="item">
              <div class="thumb">
                <img :src="product.img" />
              </div>
              <div class="down-content">
                <h4>{{ product.name }}</h4>
                <span>{{ product.brand }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>
    <div class="btn-container">
      <router-link class="button-black" to="/products">See more</router-link>
    </div>
  </div>
</template>
<script>
import Carousel from "vue-carousel/src/Carousel.vue";
import Slide from "vue-carousel/src/Slide.vue";
export default {
  data() {
    return {
      products: [
      {
          name: "MCTRL4K Controller",
          category: "led-control-system",
          slug: "mctrl4k-controller",
          brand: "Novastar",
          img: "https://oss.novastar.tech/uploads/2019/03/4K-%E6%AD%A3%E9%9D%A2-RGB-%E5%B8%A6%E7%95%8C%E9%9D%A2-e1553569482282.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_392",
          imgs: [
            "https://oss.novastar.tech/uploads/2019/03/4K-%E6%AD%A3%E9%9D%A2-RGB-%E5%B8%A6%E7%95%8C%E9%9D%A2-e1553569482282.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_392",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/ventas%2Fcontrolador-novastar-mctrl4k%2FMCTRL4K-2.jpg?alt=media&token=4c465487-1185-4fde-8821-1e45ae32ef4d",
            "https://oss.novastar.tech/uploads/2020/10/MCTRL4K-1.png",
            "https://oss.novastar.tech/uploads/2020/10/MCTRL4K-12.png",

          ],
          description:
            "MCTRL4K utilizes HDR10-Optima&HLG to realize high dynamic range display. When paired with A8s/A10s Plus, the MCTRL4K can dramatically improve image quality through the optimization of every pixel. Resolution: 4K×2K@60Hz Maximum width up to 8K.Multi Outputs:Neutrik EtherCON×16 OPT Ports×4.Multiple Video Inputs:1×DP1.2, 1×HDMI2.0, 2×Dual DVI",
        },
        {
          name: "MCTRL660 Controller",
          category: "led-control-system",
          slug: "mctrl660-controller",
          brand: "Novastar",
          img: "https://oss.novastar.tech/uploads/2019/10/MCTRL660-3.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_576",
          imgs: [
            "https://oss.novastar.tech/uploads/2019/10/MCTRL660-3.png?x-oss-process=image%2Fquality,q_50%2Fresize,m_fill,w_1024,h_576",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/nuevos%2Fnova%20660.jpg?alt=media&token=24097f30-ba80-4db7-8f68-c41094a97c1c",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/nuevos%2Fnova%20660%20back%20l.jpg?alt=media&token=f38db226-f20e-4556-98f1-9c1eb9c18d6a",
            "https://firebasestorage.googleapis.com/v0/b/avs-web-2021.appspot.com/o/nuevos%2Fnova%20660%20back.jpg?alt=media&token=970ab9d8-edc4-41b5-83f1-4e95e97e2bfa"
          ],
          description:
            "MCTRL660 is a controller developed by NovaStar with a single-unit loading capacity of 1920×1200@60Hz. MCTRL600 utilizes UART to cascade and control multiple units. Suitable for a variety of applications, especially rental and fixed installations such as stage broadcasts, monitoring centers, sports arenas, and more.12bit/10bit HDMI input, DVI input, HDMI.DVI loop output for cascading or monitoring, HDCP Blue-ray directly input, 8bit video source loading capacity of 1920×1200@60Hz., 12bit video source loading capacity of 1440×900@60Hz.Support for custom resolutions: 3840 pixels of H resolution, 3840 pixels of V resolution. Supports cascading for control of multiple units. Support for multiple signal formats: RGB, YCrCb4:2:2, YCrCb4:4:4. Certifications, CE, RoHS, FCC, UL, EAC",
        },
        {
          name: "MV0430 – Mini 4CH SDI Multiviewer",
          category: "multiviewers",
          slug: "4-channel-sdi-multiviewer",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-1.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-1.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-6.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/MV0430-5.jpg"
          ],
          description:
            "MV0430 is an exquisite and professional SDI multiviewer which features 4-channel 3G/HD/SD-SDI auto adaptive inputs and 2-channel 3G-SDI as well as 1-channel HDMI outputs. Total 16 preset layouts are provided for choices. User can also adjust the size and position of 11-character UMD and audio meter overlay freely. All the functions can be easily set by a built-in DIP switch, or PC via LAN. MV0430 is your ideal choice for broadcasting and AV post production applications.",
        },
        {
          name: "MMV1630 – 16CH SDI Mutltivewer & Switcher",
          category: "multiviewers",
          slug: "16ch-sdi-multiviewer",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-1.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-1.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-7.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-6.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-5.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/05/MMV1630-8.jpg",
          ],
          description:
            "MMV1630 is the perfect solution for a large broadcast project. One machine supports two modes, 16 channel SDI multiviewer mode and 16×16 SDI matrix switcher. The input signal Automatically detects SD, HD and 3G-SDI. The video format of multiview output can be selected from total 9 fomats from 720p to 1080p. Besides, 20 standard layouts with 16/12/11/10/9/8/6/4 preset in this device. You can even SOLO any input signal in full screen through the shortcut key. MMV16310 also supports UMD, audio meter, signal status and time code overlay. All functions can be quickly controlled by the PC software or front panel button & knob.",
        },
        {
          name: "SC1120 – SDI to HDMI Scaling Converter",
          category: "broadcast-converters",
          slug: "3g-sdi-to-hdmi-av-scaling-converter",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-6.jpg",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-6.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-3.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-5.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-4.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-2.jpg",
            "https://www.avmatrix.net/wp-content/uploads/2018/04/SC1120-1.jpg"
          ],
          description:
            "SC1120 is an exquisite and professional SDI converter to convert SDI signal to HDMI and AV signals at the same time. The format of the output can realize pass through or up/down scaling and frame rate conversion. De-interlacing function and seamless conversion can also be supported to avoid jitter. It also features AES/EBU or analog audio embedding and de-embedding. All the functions can be easily set by a built-in DIP switchers or PC via USB. SC1120 is your ideal choice for broadcasting and AV post production applications.",
        },
        {
          name: "FE1121-12G 12G-SDI Fiber Optic Extender",
          category: "fiber-optic-extenders",
          slug: "12g-sdi-fiber-optic-extender",
          brand: "Avmatrix",
          img: "https://www.avmatrix.net/wp-content/uploads/2017/09/FE1121-12G.png",
          imgs: [
            "https://www.avmatrix.net/wp-content/uploads/2017/09/FE1121-12G.png",
            "https://www.avmatrix.net/wp-content/uploads/2019/09/FE1121-12G-2.png"
          ],
          description:
            "12G/6G/3G-SDI input & loopout on transmitter Dual 12G-SDI outputs on receiver Supports up to 12G bandwidth SDI input automatic switching SD, HD, 3G, 6G, 12G-SDI 10km transmission distance SFP/ LC Fiber transmitter & receiver SDI equalization and re-clocking USB power supply",
        },
      ],
    };
  },

  components: {
    Carousel,
    Slide,
  },
  metaInfo() {
    return {
      title: "Kerosa",
    };
  },
};
</script>