<template>
  <div>
    <!-- ***** Main Banner Area Start ***** -->
    <div style="padding-top:132px;padding-bottom:32px" class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-heading">
            <h2>
              Products
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- ***** Main Banner Area End ***** -->

    <!-- ***** Products Area Starts ***** -->
    <section class="section" id="products">
      <div class="container">
        <div class="row">
          <router-link :to="`products/${product.category}/${product.slug}`" v-for="product in this.$parent.products"
            :key="product.slug" class="col-lg-4 product">
            <div class="item">
              <div v-if="loadingImg" class="thumb loader-thumb">
                <span class="loader"></span>
              </div>
              <div class="thumb">
                <img @load="IsImgLoaded()" :src="product.img" />
              </div>
              <div class="down-content">
                <h4>{{ product.name }}</h4>
                <span>{{ product.brand }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadingImg: true,
    };
  },
  methods: {
    IsImgLoaded() {
      this.loadingImg = false;
    },
  },
  metaInfo() {
    return {
      title: `Kerosa | Products`,
    };
  },

};
</script>