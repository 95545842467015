<template>
  <div>
    <!-- ***** Main Banner Area Start ***** -->
    <div style="padding-top: 132px; padding-bottom: 32px" class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-heading">
            <h2>
              {{
                this.$route.params.category
                  .replace(/-/g, " ")
                  .replace(/\w\S*/g, function (txt) {
                    return (
                      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                    );
                  })
              }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- ***** Main Banner Area End ***** -->
    <!-- ***** Products Area Starts ***** -->
    <section class="section" id="products">
      <div class="container">
        <div class="row">
          <router-link :to="`${product.category}/${product.slug}`" v-for="product in FilteredArray" :key="product.slug"
            class="col-lg-4 product">
            <div class="item">
              <div v-if="loadingImg" class="thumb loader-thumb">
                <span class="loader"></span>
              </div>
              <div class="thumb">
                <img @load="IsImgLoaded()" :src="product.img" />
              </div>
              <div class="down-content">
                <h4>{{ product.name }}</h4>
                <span>{{ product.brand }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadingImg: true,
      products: [],
    };
  },
  methods: {
    IsImgLoaded() {
      this.loadingImg = false;
    },
  },
  computed: {
    FilteredArray() {
      let products = [];
      products = this.$parent.products;
      products = products.filter(
        (item) => item.category == this.$route.params.category
      );
      return products;
    },
  },
  metaInfo() {
    return {
      title: `Kerosa | ${this.$route.params.category
        .replace(/-/g, " ")
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })}`,
    };
  },
};
</script>